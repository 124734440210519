





















































































































import { Observer } from "mobx-vue";
import { Component, Vue, Inject, Ref } from "vue-property-decorator";
import { StakingViewModel } from "../viewmodels/staking-viewmodel";
import { walletStore } from "@/stores/wallet-store";

@Observer
@Component({
  components: {},
})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: StakingViewModel;
  walletStore = walletStore;

  getLCEO() {
    window.open(`https://pancakeswap.finance/swap?outputCurrency=${process.env.VUE_APP_TOKEN_ADDRESS}`, "_blank");
  }
}
